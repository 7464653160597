#root {
  height: 100%;
}

.App {
  height: 100%;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* additions */

html, body {
  height: 100%;
  padding: 0;
  margin: 0;
  background: url(./bg.png) no-repeat center fixed;
  background-size: cover;
}

button {
  border-radius: 12px !important;
  background: #CACACA !important;
  border-color: #CACACA !important;
  color: black !important;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
}

/*
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0,0,0,1.0) !important;
}
*/

.navbar-light .navbar-nav .nav-link.active {
  text-shadow: 1px 1px black;
}
.navbar-light .navbar-nav .nav-link.active:after {
  content: "";
  display: block;
  position: relative;
  overflow: hidden;
  top: 5px;
  width: 22px;
  height: 4px;
  background: white;
  border-radius: 4px;
  margin: 0 auto;
  box-shadow: 1px 1px black;
}

#home button {
  height: 50px;
  width: 169px;
}

/*
#sell .btn-cancel:hover {
  cursor: pointer;
}
*/

/*
#sell .jam {
  border-radius: 4px;
  background-color: #f3f3f3;
}
*/

/*
#sell .default {
  height: 447px;
  width: 465px;
  padding: 40px 56px;
}
*/

/*
#sell .default label {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 2px;
  float: left;
  color: #000000;
  opacity: 0.5;
}
*/

/*
#sell .default input {
  height: 48px;
  width: 353px;
  left: 784px;
  top: 446px;
  border-radius: 4px;
  padding: 16px 12px;
  font-family: Roboto;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  border-color: white;
  background-color: white;
  ;
}
*/

/*
#sell .default .btn-submit {
  height: 50px;
  width: 354px;
}
*/

/*
#sell .success {
  height: 511px;
  width: 363px;
  padding: 24px 54px;
}

#sell .success img {
  border-radius: 4px;
}

#sell .success button {
  height: 50px;
  width: 254px;
  border-radius: 12px;
}

#sell .success .btn-cancel {
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
}
*/

/*
#auction .auction-card {
  width: 1274px;
  height: 333px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-top: 90px !important;
  overflow: hidden;
}

#auction .auction-card img {
  float: left;
  margin: 24px;
}

#auction .auction-card .auction-info {
  padding-top: 24px;
  width: 552px;
  float: left;
}

#auction .auction-card .bid-history {
  width: 375px;
  height: 333px;
  background: #C4C4C4;
  opacity: 0.2;
  float: right;
}

#auction .auction-card .bid-history .row {
  margin-left: 24px;
}

#auction .auction-card .bid-history .row.row-1 {
  width: 73px;
  height: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin-top: 24px;
}

#auction .auction-card .bid-history .row.row-2 {
  position: absolute;
  width: 327px;
  height: 104px;
  background: #FFFFFF;
  border-radius: 4px;
  margin-top: 4px;
}

#auction .auction-card .bid-history .row.row-4 {
  width: 327px;
  height: 30px;
  background: #E1E1E1;
  border-radius: 4px;
}

#auction .auction-card .auction-info .row {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 16px;
}

#auction .auction-card .auction-info .description {
  width: 552px;
  height: 161px;
  background: #f9f9f9;
  border-radius: 4px;
  color: #000000;
  padding: 14px;
  text-align: left;
}

#auction .auction-card .auction-info .row.creator-owner-name {
  margin-top: 8px;
}

#auction 
.auction-title {
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}

#auction 
.auction-id {
  float: right;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: right;
}

#auction .search::before {
  content: url(../src/search.svg);
  position: relative;
  top: 2px;
  left: 34px;
}
*/
#auction .search::before {
  content: url(../src/search.svg);
  position: relative;
  line-height: 62px;
  margin-left: 15px;
}

textarea:focus, input:focus{
  outline: none;
}

.my-auction-list-item:hover {
  font-weight: 900;
}